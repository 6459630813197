var baseCheckUrl = 'https://7whkmoybrb.execute-api.us-east-1.amazonaws.com/prod/';

function wwwLoginCheck(data) {
  var wwwData = {
    user_id: data.springbotUser,
    password: data.springbotPass
  }

  $.ajax({
    type: 'POST',
    url: baseCheckUrl + 'www',
    crossDomain: true,
    data: JSON.stringify(wwwData),
    contentType: 'application/json',
    dataType: 'json',
    success: function(res, status){
      var response = JSON.parse(res);
      if (response.status == 'ok') {
        $('[name=\'springbotUser\']').removeClass('error');
        $('[name=\'springbotPass\']').removeClass('error');
        $('[name=\'springbotUser\']').siblings('small').html(null);
        postToJira(data);
      } else {
        $('[name=\'springbotUser\']').addClass('error');
        $('[name=\'springbotPass\']').addClass('error');
        $('[name=\'springbotUser\']').siblings('small').html('Springbot login failed');
      }
    }
  });
}

function postToJira(data) {
  $.ajax({
    type: 'POST',
    url: baseCheckUrl + 'jira',
    crossDomain: true,
    data: JSON.stringify(data),
    contentType: 'application/json',
    dataType: 'json',
    success: function(res, status){
      alert('Installation request accepted.');
    }
  });
}

var loc = window.location.href;
var url = new URL(loc);
var platform = url.searchParams.get('platform');

$('#ftp-submit').click(function() {
  $(this).html('Attempting connection...');
  $(this).prop('disabled', true);
  $(this).css('opacity', 0.5);
  var arr = $(this).parents('form').serializeArray();

  var data = arr.reduce(function(map, obj) {
    map[obj.name] = obj.value;
    return map;
  }, {});

  data['port'] = parseInt(data['port'], 10);


  var sbApiUrl = 'https://api.springbot.com/ftp_check';
  var responseField = $('#ftp-response-field');
  $('input').removeClass('error');

  $.ajax({
    type: 'POST',
    url: sbApiUrl,
    crossDomain: true,
    data: JSON.stringify(data),
    contentType: 'application/json',
    dataType: 'json',
    cache: false,
    success: function(res, status) {
      responseField.empty();
      responseField.append('<p>' + res['message'] + '</p>');

      if (res['status'] == 'ok') {
        if (typeof res['error_field'] === 'undefined') {
          responseField.css('color', 'green')
        } else {
          responseField.css('color', 'red')
          if (res['error_field'] === 'userpass') {
            $('[name=\'user\']').addClass('error');
            $('[name=\'pass\']').addClass('error');
          } else if (res['error_field'] === 'hostport') {
            $('[name=\'host\']').addClass('error');
            $('[name=\'port\']').addClass('error');
          } else {
            $('[name=\'' + res['error_field'] + '\']').addClass('error');
          }
        }
      } else {
        responseField.css('color', 'red');
      }
    },
    error: function(res, status) {
      responseField.empty();
      responseField.append('<p style="color: red;">' + res['message'] + '</p>');
    },
    complete: function() {
      $('#ftp-submit').html('Check settings');
      $('#ftp-submit').prop('disabled', false);
      $('#ftp-submit').css('opacity', 1.0);
    }
  });
});


$(document).ready(function() {
  $('a[href="/integrations/shopify.html"]').click(function () {

    var $overlay = $('<div class="grey-out-overlay"/>').appendTo('body');
    var $modal = $('<div class="shopify-modal"></div>').appendTo('body');

    $('<p>Please enter your myshopify store domain</p>').appendTo($modal);
    var $shopifyDomainInput = $('<input type="text" id="myshopify-domain-input">').appendTo($modal);
    var $shopifyInstallButton = $('<button>Install</button>').appendTo($modal);

    $shopifyInstallButton.click(function () {

      var input = $($shopifyDomainInput).val();
      var foundDomain = input.match(/[\_\-a-zA-Z0-9]+\.myshopify\.com/i);

      if (foundDomain != null && foundDomain.length > 0) {
        window.location.replace('https://prod-shopify-etl.herokuapp.com/create/2?shop=' + foundDomain[0].toLowerCase());
        $overlay.remove();
        $modal.remove();
      } else {
        alert('Your shopify domain should end with .myshopify.com');
      }

    });

    $overlay.click(function () {
      $overlay.remove();
      $modal.remove();
    });

    return false;
  });
});
